import React from 'react';
import Grid from '../../Components/Grid';
import GridItem from '../../Components/Grid/GridItem';

const About = () => (
  <Grid>
    <GridItem>About Me</GridItem>
  </Grid>
);

export default About;
